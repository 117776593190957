















import { Component, Vue } from "vue-property-decorator";
import DashCitasPendientesModule from "@/store/modules/Dashboards/PacientesYcita-module";
import { PacientesYcita } from "@/shared/dtos/PacientesYcita";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
@Component({
  components: {}
})
export default class PacientesCitas extends Vue {
  public created() {
    DashCitasPendientesModule.getPacientesYcitas();
  }
  public get headers() {
    return [
      { text: "Nombre", value: "nombre" },
      { text: "Consultas totales", value: "total_consultas" }
    ];
  }
  public get datos() {
    return DashCitasPendientesModule.PacientesYcitas;
  }

  public edit_paci(paciente: PacientesYcita) {
    this.$router.push({
      name: RouterNames.pacientesedit,
      query: { id: UtilsEncript.Encriptar(paciente.numero.toString()) }
    });
  }
}
