import { store } from '@/store/store';
import { PacientesYcita } from '@/shared/dtos/PacientesYcita';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'PacientesYcitaModule',
    store,
    dynamic: true
})
class PacientesYcitaModule extends VuexModule {
    public PacientesYcitas: PacientesYcita[] = [];
    public PacienteMasCitas: PacientesYcita = new PacientesYcita();
    @Action({ commit: 'onGetPacientesYcitas' })
    public async getPacientesYcitas() {
        return await ssmHttpService.get(API.DashCitasRealizadasPorPaciente, null, false);
    }
    @Action({ commit: 'onGetPacienteYcitas' })
    public async getPaciMasCitas() {
        return await ssmHttpService.get(API.DashPacienteConMasCitas, null, false);
    }

    @Mutation
    public onGetPacientesYcitas(res: PacientesYcita[]) {
        this.PacientesYcitas = res ? res.map((x) => new PacientesYcita(x)) : [];
    }
    @Mutation
    public onGetPacienteYcitas(res: PacientesYcita) {
        this.PacienteMasCitas = new PacientesYcita(res);
    }
}
export default getModule(PacientesYcitaModule);